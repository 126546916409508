export const availablePaymentType = [
  { value: 'CB', label: 'CB' },
  { value: 'Cheque', label: 'Cheque' },
  { value: 'Virement', label: 'Virement' },
  { value: 'Espèce', label: 'Espèce' },
  { value: 'Web', label: 'Web' },
]

export const availablePaymentStatus = [
  { value: 'Payée', label: 'Payée' },
  { value: 'Acompte', label: 'Acompte' },
  { value: 'Non Payée', label: 'Non Payée' },
  { value: 'Rib envoyé ', label: 'Rib envoyé ' },
]

export const availablePriceType = [
  { value: 'Full Retail', label: 'Full Retail' },
  { value: 'Associé', label: 'Associé' },
  { value: 'Offert', label: 'Offert' },
  { value: 'Remplacement', label: 'Remplacement' },
  { value: 'Prix lancement', label: 'Prix lancement' },
  { value: 'Réduction ', label: 'Réduction ' },
]

export const availableChannelType = [
  { value: 'Vente Particulière', label: 'Vente Particulière' },
  { value: 'Vente Ambassadrice', label: 'Vente Ambassadrice' },
  { value: 'E-casa', label: 'E-casa' },
  { value: 'Casa/Mini Casa', label: 'Casa/Mini Casa' },
  { value: 'Pop Up/Collab', label: 'Pop Up/Collab' },
  { value: 'Wholesale', label: 'Wholesale' },
]

export const availableProductSize = [
  { value: 35, label: 35 },
  { value: 35.5, label: 35.5 },
  { value: 36, label: 36 },
  { value: 36.5, label: 36.5 },
  { value: 37, label: 37 },
  { value: 37.5, label: 37.5 },
  { value: 38, label: 38 },
  { value: 38.5, label: 38.5 },
  { value: 39, label: 39 },
  { value: 39.5, label: 39.5 },
  { value: 40, label: 40 },
  { value: 40.5, label: 40.5 },
  { value: 41, label: 41 },
  { value: 41.5, label: 41.5 },
  { value: 42, label: 42 },
  { value: 42.5, label: 42.5 },
  { value: 43, label: 43 },
  { value: 43.5, label: 43.5 },
  { value: 44, label: 44 },
  { value: 44.5, label: 44.5 },
  { value: 45, label: 45 },
  { value: 45.5, label: 45.5 },
  { value: 46, label: 46 },
]

export const availableProductHeel = [
  { value: 'T1,5', label: 'T1,5' },
  { value: 'T5', label: 'T5' },
  { value: 'T6', label: 'T6' },
  { value: 'T7', label: 'T7' },
  { value: 'T8', label: 'T8' },
  { value: 'T9', label: 'T9' },
  { value: 'T10', label: 'T10' },
]

export const availableProductColors = [
  { value: 'africa', label: 'africa' },
  { value: 'azzuro', label: 'azzuro' },
  { value: 'bianco', label: 'bianco' },
  { value: 'bianco e nero', label: 'bianco e nero' },
  { value: 'Blu 493', label: 'Blu 493' },
  { value: 'blu ch', label: 'blu ch' },
  { value: 'blu navy', label: 'blu navy' },
  { value: 'Blu notte 682', label: 'Blu notte 682' },
  { value: 'blu velvet', label: 'blu velvet' },
  { value: 'blu viola', label: 'blu viola' },
  { value: 'bohemian green', label: 'bohemian green' },
  { value: 'bronze', label: 'bronze' },
  { value: 'cachemire nero', label: 'cachemire nero' },
  { value: 'camel', label: 'camel' },
  { value: 'caravaggio', label: 'caravaggio' },
  { value: 'cava nero', label: 'cava nero' },
  { value: 'caviar oro', label: 'caviar oro' },
  { value: 'chiffon blu', label: 'chiffon blu' },
  { value: 'chiffon french lover', label: 'chiffon french lover' },
  { value: 'chiffon nero', label: 'chiffon nero' },
  { value: 'craquelet oro', label: 'craquelet oro' },
  { value: 'dalma', label: 'dalma' },
  { value: 'dandy', label: 'dandy' },
  { value: 'dark forest', label: 'dark forest' },
  { value: 'eclypse blu', label: 'eclypse blu' },
  { value: 'etoile apus', label: 'etoile apus' },
  { value: 'etoile cassiopea', label: 'etoile cassiopea' },
  { value: 'etoile centauro', label: 'etoile centauro' },
  { value: 'framboise', label: 'framboise' },
  { value: 'framboise V10499', label: 'framboise V10499' },
  { value: 'fucsia 494', label: 'fucsia 494' },
  { value: 'Fucsia V10987', label: 'Fucsia V10987' },
  { value: 'doré nut', label: 'doré nut' },
  { value: 'gala ariel', label: 'gala ariel' },
  { value: 'gala sun', label: 'gala sun' },
  { value: 'girafa', label: 'girafa' },
  { value: 'glitter blu', label: 'glitter blu' },
  { value: 'glitter mina', label: 'glitter mina' },
  { value: 'glitter nero', label: 'glitter nero' },
  { value: 'glitter night', label: 'glitter night' },
  { value: 'jaune 479', label: 'jaune 479' },
  { value: 'jaune V10927', label: 'jaune V10927' },
  { value: 'kaki 698', label: 'kaki 698' },
  { value: 'havana', label: 'havana' },
  { value: 'lama gris', label: 'lama gris' },
  { value: 'laser', label: 'laser' },
  { value: 'leopardo', label: 'leopardo' },
  { value: 'lilas 482', label: 'lilas 482' },
  { value: 'lili rose', label: 'lili rose' },
  { value: 'marone', label: 'marone' },
  { value: 'misty rose', label: 'misty rose' },
  { value: 'moucheté', label: 'moucheté' },
  { value: 'oro zaniahCH', label: 'oro zaniahCH' },
  { value: 'petrole V10780', label: 'petrole V10780' },
  { value: 'petrolio', label: 'petrolio' },
  { value: 'prune', label: 'prune' },
  { value: 'razza blu', label: 'razza blu' },
  { value: 'razza nera', label: 'razza nera' },
  { value: 'razza safran', label: 'razza safran' },
  { value: 'razza verde', label: 'razza verde' },
  { value: 'rocher alena', label: 'rocher alena' },
  { value: 'rocher ariel', label: 'rocher ariel' },
  { value: 'rocher cassiopea', label: 'rocher cassiopea' },
  { value: 'rocher vega', label: 'rocher vega' },
  { value: 'rocher zaniah', label: 'rocher zaniah' },
  { value: 'rosa 498', label: 'rosa 498' },
  { value: 'rosa V10313', label: 'rosa V10313' },
  { value: 'rosso 716', label: 'rosso 716' },
  { value: 'rosso chiaro', label: 'rosso chiaro' },
  { value: 'rosso coromendel', label: 'rosso coromendel' },
  { value: 'rosso malice', label: 'rosso malice' },
  { value: 'rosso ruby', label: 'rosso ruby' },
  { value: 'royal blu', label: 'royal blu' },
  { value: 'safari', label: 'safari' },
  { value: 'Salmone V10751', label: 'Salmone V10751' },
  { value: 'Senape', label: 'Senape' },
  { value: 'Serpente', label: 'Serpente' },
  { value: 'ultra violet', label: 'ultra violet' },
  { value: 'vert monet', label: 'vert monet' },
  { value: 'violet V10315', label: 'violet V10315' },
  { value: 'violet V10993', label: 'violet V10993' },
  { value: 'yuta cassiopea', label: 'yuta cassiopea' },
  { value: 'veleno', label: 'veleno' },
  { value: 'BjNoir', label: 'BjNoir' },
  { value: 'BjViolet', label: 'BjViolet' },
  { value: 'BjFucxia', label: 'BjFucxia' },
  { value: 'Rouge Winter', label: 'Rouge Winter' },
  { value: 'andromeda', label: 'andromeda' },
  { value: 'arancione', label: 'arancione' },
  { value: 'cava safran', label: 'cava safran' },
]
