import { defineComponent } from 'vue'
import {
  Download as DownloadIcon,
  ChatSquare as CommentIcon,
  Warning as WarningIcon,
} from '@element-plus/icons'

import DsView from '@/components/DsView'
import { Model, Reference } from '@/components/DsForm'
import { Column } from '@/components/DsTable'
import { formatTimeSpent } from '@/utils/datetimes'
import {
  availableProductSize,
  availableProductColors,
  availableProductHeel,
} from '@/assets/enums'

import { ElTooltip } from 'element-plus'

const recipeModel: Model = {
  type: 'object',
  indent: false,
  properties: {
    master_recipe: { type: 'string', label: 'Recipe Group', required: true },
    product: { type: 'string', label: 'Name', required: true },
    owner: {
      type: 'uuid',
      model: 'users',
      label: 'Owner',
      optionLabel: (user: any) => `${user.name} ${user.lastname}`,
    },
    workshop: {
      type: 'string',
      label: 'Workshop',
      required: true,
    },
    order_id: {
      type: 'uuid',
      model: 'orders',
      label: 'Order',
      required: true,
      optionLabel: (order: any) =>
        `${order.order_number} ${order.product ? '- ' + order.product : ''}`,
    },
    description: {
      type: 'string',
      label: 'Description',
    },
    size: {
      type: 'string',
      enum: availableProductSize,
      allowCreate: true,
      label: 'Size',
    },
    heel: {
      type: 'string',
      enum: availableProductHeel,
      allowCreate: true,
      label: 'Heel',
    },
    color: {
      type: 'string',
      enum: availableProductColors,
      allowCreate: true,
      label: 'Color',
    },
    picture: {
      type: 'file',
      label: 'Picture',
    },
    comment: {
      type: 'string',
      label: 'Comment',
    },
    steps: {
      type: 'array',
      propertiesLayout: 'section',
      label: 'Steps',
      itemKeyName: 'step_name',
      items: {
        type: 'object',
        label: 'Step',
        properties: {
          step_name: { type: 'string', label: 'Step Name', required: true },
          owner: {
            type: 'uuid',
            model: 'users',
            label: 'Owner',
            required: true,
            optionLabel: (user: any) => `${user.name} ${user.lastname}`,
          },
          description: { type: 'string', label: 'Description' },
          comment: { type: 'string', label: 'Comment' },
          depend_on: {
            type: 'string',
            label: 'Depend on',
            enum: [
              {
                value: 1,
                label: 'Write the name(s) of the previous step(s)',
                disabled: true,
              },
            ],
            multiple: true,
            allowCreate: true,
          },
          components: {
            type: 'array',
            itemKeyName: 'alias',
            items: {
              type: 'object',
              label: 'Component',
              properties: {
                alias: {
                  type: 'string',
                  label: 'Component Alias',
                },
                component_id: {
                  type: 'uuid',
                  model: 'components',
                  label: 'Component',
                  required: true,
                },
                quantity: {
                  type: 'number',
                  label: 'Quantity',
                  required: true,
                  suffix: new Reference(
                    'local',
                    'component_id',
                    'components',
                    'unit'
                  ),
                },
              },
            },
            label: 'Components',
          },
          pictures: {
            type: 'file',
            label: 'Picture',
          },
        },
      },
    },
  },
}

const templateModel: Model = {
  type: 'object',
  indent: false,
  properties: {
    order_id: {
      type: 'uuid',
      model: 'orders',
      label: 'Order',
      required: true,
      optionLabel: (order: any) => {
        return `${order.order_number} ${
          order.product ? '- ' + order.product : ''
        }`
      },
      filter: (
        order: { [key: string]: any },
        relations: { [key: string]: { [key: string]: any } }
      ) => {
        if ('recipes' in relations) {
          return !Object.values(relations['recipes']).some(
            (recipe) => 'order_id' in recipe && recipe.order_id === order.id
          )
        }
        return false
      },
    },
    product_id: {
      type: 'uuid',
      model: 'recipes',
      label: 'Template',
      required: true,
      filter: (recipe: any, relations: Record<string, any>) => {
        const orderId = relations.templateValue.order_id
        if (orderId) {
          const order = relations.orders[orderId]
          if (order) {
            return !recipe.order_id
          }
        }
        return !recipe.order_id
      },
      optionLabel: (recipe: any) => recipe.product,
    },
  },
}

const renderTable = (tasks: any, relations: any) => {
  return (
    <table
      class="task_infos"
      style="position:relative;border-spacing: 0;display:block"
    >
      {tasks &&
        Object.values(tasks).map((task: any) => {
          if (task.status === 'active') {
            let task_owner
            if (task.owner && relations['users']) {
              task_owner = relations['users'][task.owner]
            }
            let timeSinceTaskStarted: number | Date = new Date(0)
            for (const dependencie of task.upstream || []) {
              timeSinceTaskStarted =
                new Date(tasks[dependencie].status) > timeSinceTaskStarted
                  ? new Date(tasks[dependencie].status)
                  : timeSinceTaskStarted
            }
            const elapsedTime =
              timeSinceTaskStarted.getTime() !== 0
                ? formatTimeSpent(timeSinceTaskStarted.getTime())
                : null
            return (
              <tr
                style={task.error ? 'background-color: #fcedeb;' : ''}
                class="subrows"
              >
                <td style="min-width: 20px;">
                  {task.error && (
                    <ElTooltip
                      trigger="hover"
                      content={task.error}
                      v-slots={{
                        default: () => (
                          <WarningIcon style="height: 20px;color:#f56c6c;vertical-align: middle;" />
                        ),
                      }}
                    />
                  )}
                </td>
                <td>
                  <span style="vertical-align: middle;">{task.label}</span>
                </td>

                <td>
                  {task_owner
                    ? `${task_owner.name} ${task_owner.lastname}`
                    : ''}
                </td>
                <td>{elapsedTime || 'Not started yet'}</td>
              </tr>
            )
          }
        })}
    </table>
  )
}

const columns: Column[] = [
  { key: 'identifier', header: 'PO #' },
  { key: 'product', header: 'Product' },
  {
    key: 'comment',
    header: '',
    icon: true,
    formatter: (value) => {
      return typeof value === 'string' ? (
        <ElTooltip
          trigger="hover"
          content={value}
          v-slots={{
            default: () => <CommentIcon style="height: 20px;color:grey" />,
          }}
        />
      ) : (
        ''
      )
    },
    sortable: false,
  },
  {
    key: 'order_id',
    header: 'Client',
    formatter: (value: unknown, relations: any) => {
      if (
        relations['orders'] &&
        typeof value === 'string' &&
        relations['clients']
      ) {
        const order = relations['orders'][value]
        const client =
          order && order.client_id in relations['clients']
            ? relations['clients'][order.client_id]
            : null
        if (client) {
          return `${client.name} ${
            client.lastname && typeof client.lastname === 'string'
              ? `${client.lastname.charAt(0)}.`
              : ''
          }`
        }
      }
      return ''
    },
  },
  {
    key: 'created_at',
    header: 'Received',
    defaultSort: 'desc',
    formatter: (value) =>
      formatTimeSpent(typeof value === 'string' ? value : null),
  },
  {
    key: 'workflow',
    header: 'Active tasks',
    formatter: (tasks: any, relations: any) => renderTable(tasks, relations),
  },
]

const defaultOrder = (): any => ({
  id: crypto.randomUUID(),
  product: '',
  owner: '',
  order_id: '',
  steps: [],
})

import { getPdf } from '@/components/PdfViewer'

export default defineComponent({
  name: 'Recipes',
  components: { DsView },
  setup() {
    return () => (
      <DsView
        view={{
          collection: 'recipes',
          workflow: true,
          title: 'Production Orders',
          model: recipeModel,
          columns: columns,
          headerKey: 'product',
          createButtonLabel: 'New Production Order',
          history: false,
          templateModel: {
            model: templateModel,
            default: { product_id: null, order_id: null },
          },
          rowKey: 'id',
          mainAction: {
            icon: DownloadIcon,
            label: 'Bolla PDF',
            callback: getPdf,
          },
          customRelations: ['clients'],
        }}
        defaultValue={defaultOrder}
        backendDefaultFilter={{
          type: 'group',
          condition: 'AND',
          filters: [
            {
              type: 'filter',
              property: 'order_id',
              operator: 'IS_NOT_EMPTY',
              value: '',
            },
          ],
        }}
      />
    )
  },
})
